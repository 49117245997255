import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../history";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import { isMobile, withOrientationChange } from "react-device-detect";
import Select from "react-select";
import { Form, FormGroup } from "reactstrap";
class Signup extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    city: "",
    position: "",
    company: "",
    phone: "",
    isloading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "",
    category: null,
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
    history.push("/login");
  };
  handleAlert2 = (state, value) => {
    this.setState({ [state]: value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.category === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select category.",
      });
    } else {
      this.setState({
        isloading: true,
      });
      var registeruser = firebase
        .app()
        .functions("asia-east2")
        .httpsCallable("createuser");
      registeruser({
        name: this.state.name,
        company: this.state.company,
        position: this.state.position,
        phone: this.state.phone,
        email: this.state.email,
        city: this.state.city,
        time: new Date().toString(),
        category:this.state.category.value
        // password: this.state.password
      }).then((data) => {
        console.log(data);
        console.log(data.data.err);
        console.log(JSON.stringify(data));
        if (data.data.err == 0) {
          console.log("REgister done");
          this.setState({
            successAlert: true,
            isloading: false,
          });
          // history.push("/login");
        } else if (data.data.err === 1) {
          console.log("Already registered");
          this.setState({
            errorAlert: true,
            errormsg: "Already Registered",
            isloading: false,
          });
        } else {
          console.log("There was an error");
          this.setState({
            errorAlert: true,
            errormsg: "There was an error",
            isloading: false,
          });
        }
      });
    }
  };
  render() {
    return (
      <section className="signup-area">
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
          Thank you for registering. Please check your mail.
          </p>
        </SweetAlert>
        {isMobile && !this.props.isLandscape ? (
          <img
            src={require("../../assets/images/blue.jpg")}
            height="100%"
            style={{ position: "absolute", zIndex: -1 }}
          />
        ) : (
          <img
            src={require("../../assets/images/signup2.jpg")}
            width="100%"
            style={{ position: "absolute", zIndex: -1 }}
          />
        )}
        {isMobile && !this.props.isLandscape ? (
          <div style={{ textAlign: "center" }}>
            {" "}
            <img
              src={require("../../assets/images/hitachilogored.png")}
              width="80%"
              style={{ padding: "10%" }}
              // style={{ position: "absolute", zIndex: -1 }}
            />
            <img
              src={require("../../assets/images/eventlogo.png")}
              width="100%"
              // style={{ position: "absolute", zIndex: -1 }}
            />
          </div>
        ) : null}
        <SweetAlert
          
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert2("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div
          className={
            isMobile && !this.props.isLandscape
              ? "d-table"
              : "d-table registerbox"
          }
        >
          <div className="d-table-cell">
            <div className="signup-form">
              {/* <h3>Create your Account</h3> */}

              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <div className="form-group">
                  <label>Organization</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Organization"
                    value={this.state.company}
                    onChange={(e) => this.setState({ company: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Designation</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Designation"
                    value={this.state.position}
                    onChange={(e) =>
                      this.setState({ position: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <Select
                    required
                    className="React"
                    classNamePrefix="select"
                    value={this.state.category}
                    onChange={(a) => {
                      this.setState({
                        category: a,
                      });
                    }}
                    placeholder="Job Title*"
                    options={[
                      {
                        value: "Partner",
                        label: "Partner",
                      },
                      {
                        value: "Sponsor",
                        label: "Sponsor",
                      },
                      {
                        value: "Hitachi Vantara",
                        label: "Hitachi Vantara",
                      },
                    ]}
                  />
                  {/* <Label>Company size </Label> */}
                </div>
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>City</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={this.state.city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                  />
                </div>
                {/* <div className="form-group">
                                    <label>Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Password" 
                                        value={this.state.password}
                                        onChange={e => this.setState({ password: e.target.value })}
                                    />
                                </div> */}

                <button
                  disabled={this.state.isloading}
                  type="submit"
                  className="btn btn-primary"
                >
                  {this.state.isloading ? <Spinner color="red" /> : "Register"}
                </button>

                <p>
                  Already a registered user? <Link to="/login">Login!</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withOrientationChange(Signup);
