import firebase from "firebase";
import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "reactstrap";
import { isMobile,withOrientationChange } from "react-device-detect";

class Login extends React.Component {
  state = {
    email: "",
    password: "password",
    isLoading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "There was error please check your credentils",
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.email.length > 0) {
      this.setState({
        isLoading: true,
      });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(() => {
          console.log("User logged in");
        })
        .catch((error) => {
          console.log(error);
          if (error.code && error.code == "auth/user-not-found") {
            this.setState({
              errorAlert: true,
              isLoading: false,
              errormsg: "Email not found",
            });
          } else if (error.code && error.code == "auth/wrong-password") {
            this.setState({
              errorAlert: true,
              isLoading: false,
              errormsg: "Password Incorrect",
            });
          } else if (error.code && error.code == "auth/invalid-email") {
            this.setState({
              errorAlert: true,
              isLoading: false,
              errormsg: "The username is badly formatted",
            });
          } else {
            if (error.message.includes("IDBDatabase")) {
              window.location.reload();
            } else {
              this.setState({
                errorAlert: true,
                isLoading: false,
                errormsg: error.message,
              });
            }
          }
        });
    }
  };

  render() {
    return (
      <section className="login-area">
        {isMobile &&!this.props.isLandscape ? (
          <img
            src={require("../../assets/images/blue.jpg")}
            height="100%"
            style={{ position: "absolute", zIndex: -1 }}
          />
        ) : (
          <img
            src={require("../../assets/images/signup2.jpg")}
            width="100%"
            style={{ position: "absolute", zIndex: -1 }}
          />
        )}
        {isMobile &&!this.props.isLandscape ? (
          <div style={{textAlign:"center"}}>
            {" "}
            <img
              src={require("../../assets/images/hitachilogored.png")}
              width="80%"
              style={{paddingLeft:"10%",paddingRight:"10%"}}
              // style={{ position: "absolute", zIndex: -1 }}
            />
               <img
              src={require("../../assets/images/eventlogo.png")}
              width="100%"
              style={{paddingLeft:"5%",paddingRight:"5%",paddingBottom:"5%"}}
              // style={{ position: "absolute", zIndex: -1 }}
            />
          </div>
        ) : null}
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            An email has been sent to your registered email ID to reset your
            password. Please check your inbox!
          </p>
        </SweetAlert>

        <SweetAlert
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div className={isMobile &&!this.props.isLandscape ? "d-table" : "d-table loginbox"}>
          <div className="d-table-cell">
            <div className="login-form">
              {/* <h3>Welcome Back!</h3> */}

              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                {/* <div className="form-group">
                                    <label>Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Password" 
                                        value={this.state.password}
                                        onChange={ e => this.setState({ password: e.target.value }) }
                                    />
                                </div> */}

                <button
                  disabled={this.state.isLoading}
                  type="submit"
                  className="btn btn-primary"
                >
                  {this.state.isLoading ? <Spinner color="white" /> : "Login"}
                </button>

                <p>
                  <Link to="/signup" className="pull-left">
                    Create a new account
                  </Link>

                  {/* <Link to="#" className="pull-right">Forgot your password?</Link> */}
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withOrientationChange(Login);
